
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import HomeDashboardSection from '@/components/sections/dashboard/HomeDashboardSection.vue'

export default defineComponent({
  name: 'Home',
  components: {
    Main,
    HomeDashboardSection
  }
})
