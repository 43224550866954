
import { defineComponent } from 'vue'
// import Instagram from '@/assets/svg/home-dashboard/instagram.svg?inline'
// import Tiktok from '@/assets/svg/home-dashboard/Tiktok.svg?inline'
// import Coins from '@/assets/svg/home-dashboard/coins.svg?inline'

export default defineComponent({
  name: 'HomeDashboardSection',
  data () {
    return {
      socialShare: false
    }
  },
  components: {
    // Instagram,
    // Tiktok,
    // Coins
  },
  methods: {
    share () {
      this.socialShare = true
    }
  }
})
